<template>
  <!-- reset:password START -->
  <div class="account-reset-password">

    <div class="description">
      <p v-html="`${$t('login.forgotpasswordheadline')}`"></p>
      <p class="thin" v-html="`${$t('login.forgotpassworddescription')}`"></p>
    </div>

    <!-- form:reset:password START -->
    <el-form
      status-icon
      :rules="rules"
      ref="resetPasswordForm"
      :model="resetPasswordForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="executeRecaptcha"
    >
      <!-- email/phone-switch START -->
      <el-form-item>
        <el-switch
          v-model="methodResetPassword"
          :inactive-text="$t('login.email')"
          :active-text="$t('login.phone')"
          active-color="#353635"
          inactive-color="#A0A0A0"
        ></el-switch> <!-- v-on:change="resetForm('resetPasswordForm')" -->
      </el-form-item>
      <!-- email/phone-switch END -->

      <!-- form:reset:password:email START -->
      <el-form-item prop="email" v-if="!methodResetPassword">
        <el-input
          inputmode="email"
          :placeholder="`${$t('login.email')} *`"
          v-model="resetPasswordForm.email"
          @keyup.enter.native="executeRecaptcha"
        ></el-input>
      </el-form-item>
      <!-- form:reset:password:email END -->

      <!-- form:reset:password:phone START -->
      <el-form-item prop="phone" v-else>
        <el-input
          inputmode="tel"
          :placeholder="`${$t('login.phone')} *`"
          v-model="resetPasswordForm.phone"
          @keyup.enter.native="executeRecaptcha"
        ></el-input>
      </el-form-item>
      <!-- form:reset:password:phone END -->

      <!-- form:reset:password:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="executeRecaptcha"
        >{{ $t('login.send') }}</el-button>
      </el-form-item>
      <!-- form:reset:password:submit END -->

      <!-- form:google-recaptcha:submit START -->
      <recaptcha
        ref="recaptcha"
        v-on:verify="verifyForm"
      ></recaptcha>
      <!-- form:google-recaptcha:submit END -->
    </el-form>
    <!-- form:reset:password END -->

    <!-- form:reset:password:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:reset:password:loading END -->

  </div>
  <!-- reset:password END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'reset-password',
  mixins: [titleMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
    Recaptcha: () => import('../../components/shared/Recaptcha.vue'),
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s\- /()]*)$/mg; // /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s]*)$/mg; // eslint-disable-line
      if (!value) {
        return callback(new Error('Phone number cannot be empty'));
      }
      if (phoneReg.test(value)) {
        callback();
      } else {
        callback(new Error('The phone number format is incorrect'));
      }
      return value;
    };
    return {
      googleRecaptchaToken: '',
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('login.resetpassword'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Method switch
       */
      methodResetPassword: false,

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      user: null,
      messagePassword: this.$t('login.messagepassword'),
      confirmationPassword: this.$t('login.confirmationpassword'),
      resetPasswordForm: {
        email: '',
        phone: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        email: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            type: 'email',
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        phone: [
          {
            required: true,
            validator: checkPhone,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  async created() {
    this.user = await this.FETCH_USER();

    // populate user data
    if (this.user) {
      this.resetPasswordForm.email = this.user.email;
      this.resetPasswordForm.phone = this.user.phoneNumber;
    }
  },
  methods: {
    async FETCH_USER() {
      await this.$store.dispatch('setUser');
      return this.$store.getters.user;
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'resetPasswordForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Recaptcha
             */
            formData.append('g-recaptcha-response', this.googleRecaptchaToken);
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));

            /**
             * If method equal email / else method is phone
             */
            if (!this.methodResetPassword) {
              /**
               * Data
               */
              formData.append('email', this.resetPasswordForm.email);

              // Display the key/value pairs
              /* [...formData.entries()].forEach((row) => {
                console.log(`${row}`);
              }); */

              // Update user data
              this.$http.post(`${process.env.VUE_APP_BASE_URL}passwordReset`, formData, {
                headers: {
                  'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                  Authorization: `Bearer ${localStorage.getItem('auth')}`,
                },
              })
                .then((response) => {
                  if (response.data) {
                    // Close loading
                    this.loadingVisible = false;

                    // notification - error
                    this.success(response.data.message);
                  } else {
                    this.globalErrorMessage = true;
                    this.resetForm.agbs = false;

                    console.log('error register submit!!');
                  }
                })
                .catch((error) => {
                  if (!error.response) {
                    // network error
                    this.errorStatus = 'Error: Network Error';
                  } else {
                    this.errorStatus = error.response.data;
                    this.loadingVisible = false;
                    this.serverErrorMessages = this.errorStatus.errors;

                    this.serverErrorMessages.forEach((message) => {
                      this.error(`${this.$t(`error.${message.code}`)}`);
                    });
                  }
                });
            } else {
              formData.append('phoneNumber', this.resetPasswordForm.phone);

              // Display the key/value pairs
              /* [...formData.entries()].forEach((row) => {
                console.log(`${row}`);
              }); */

              // Update user data
              this.$http.post(`${process.env.VUE_APP_BASE_URL}passwordResetSMS`, formData, {
                headers: {
                  'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                  Authorization: `Bearer ${localStorage.getItem('auth')}`,
                },
              })
                .then((response) => {
                  if (response.data) {
                    // Close loading
                    this.loadingVisible = false;

                    // notification - error
                    this.success(response.data.message);
                  } else {
                    this.globalErrorMessage = true;
                    this.resetForm.agbs = false;

                    console.log('error register submit!!');
                  }
                })
                .catch((error) => {
                  if (!error.response) {
                    // network error
                    this.errorStatus = 'Error: Network Error';
                  } else {
                    this.errorStatus = error.response.data;
                    this.loadingVisible = false;
                    this.serverErrorMessages = this.errorStatus.errors;

                    this.serverErrorMessages.forEach((message) => {
                      this.error(`${this.$t(`error.${message.code}`)}`);
                    });
                  }
                });
            }
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    success(message) {
      if (message) {
        this.$notify({
          message,
          type: 'success',
          position: 'bottom-right',
        });
      } else {
        this.$notify({
          message: this.$t('error.sentifexists'),
          type: 'success',
          position: 'bottom-right',
        });
      }

      // clean up input fields
      // setTimeout(() => {
      //   this.resetForm('resetPasswordForm');
      // }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },

    verifyForm(googleRecaptchaResponse) {
      if (googleRecaptchaResponse) {
        this.googleRecaptchaToken = googleRecaptchaResponse;
        this.submitForm('resetPasswordForm');
      }
    },

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>
